@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ql-size-12 {
  font-size: calc((12 / 1920) * 100vw); /* 0.625vw */
}

.ql-size-16 {
  font-size: calc((16 / 1920) * 100vw); /* 0.833vw */
}

.ql-size-24 {
  font-size: calc((24 / 1920) * 100vw); /* 1.25vw */
}

.ql-size-28 {
  font-size: calc((28 / 1920) * 100vw); /* 1.458vw */
}

.ql-size-32 {
  font-size: calc((32 / 1920) * 100vw); /* 1.667vw */
}

.ql-size-40 {
  font-size: calc((40 / 1920) * 100vw); /* 2.083vw */
}

.ql-size-48 {
  font-size: calc((48 / 1920) * 100vw); /* 2.5vw */
}

.ql-size-56 {
  font-size: calc((56 / 1920) * 100vw); /* 2.917vw */
}

.ql-size-64 {
  font-size: calc((64 / 1920) * 100vw); /* 3.333vw */
}

.ql-size-72 {
  font-size: calc((72 / 1920) * 100vw); /* 3.75vw */
}

.ql-size-80 {
  font-size: calc((80 / 1920) * 100vw); /* 4.167vw */
}

.ql-size-88 {
  font-size: calc((88 / 1920) * 100vw); /* 4.583vw */
}

.ql-size-96 {
  font-size: calc((96 / 1920) * 100vw); /* 5vw */
}

.ql-size-104 {
  font-size: calc((100 / 1920) * 100vw); /* 5.208vw */
}

.ql-size-112 {
  font-size: calc((112 / 1920) * 100vw); /* 5.833vw */
}

.ql-size-120 {
  font-size: calc((120 / 1920) * 100vw); /* 6.25vw */
}

.ql-size-128 {
  font-size: calc((128 / 1920) * 100vw); /* 6.667vw */
}

.ql-size-136 {
  font-size: calc((136 / 1920) * 100vw); /* 7.083vw */
}

.ql-size-144 {
  font-size: calc((144 / 1920) * 100vw); /* 7.5vw */
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
